var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "content m-3" }, [
      _c("div", { staticClass: "card border-bottom" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row mx-1" }, [
            _c("div", { staticClass: "col-12 col-sm-2 my-auto" }, [
              _vm.showAddOferta == false
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success btn m-r-10",
                      on: { click: _vm.addOfertaClick }
                    },
                    [
                      _c("i", { staticClass: "fas fa-plus" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "d-none d-sm-inline" }, [
                        _vm._v("Oferta noua")
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showAddOferta == true
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-danger btn m-r-10",
                      on: { click: _vm.cancel }
                    },
                    [
                      _c("i", { staticClass: "fas fa-times" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "d-none d-sm-inline" }, [
                        _vm._v("Anulare")
                      ])
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.showAddOferta == false
              ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Cauta")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filterOfertaList.keyword,
                          expression: "filterOfertaList.keyword"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        name: "keyword",
                        placeholder: "Nume, Telefon, Email"
                      },
                      domProps: { value: _vm.filterOfertaList.keyword },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.filterOfertaList,
                            "keyword",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ])
              : _vm._e()
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "content m-3" }, [
      _c("div", { staticClass: "card border-bottom" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("h4", { staticClass: "m-1" }, [_vm._v("Date client")]),
          _vm._v(" "),
          _c("div", { staticClass: "row mx-1" }, [
            _c("div", { staticClass: "col-12 col-sm-3 my-auto" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Nume")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newClientForm.nume,
                        expression: "newClientForm.nume"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      value: "",
                      disabled: _vm.isDisabled,
                      name: "nume"
                    },
                    domProps: { value: _vm.newClientForm.nume },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.newClientForm, "nume", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: {
                      "form-name": _vm.newClientForm,
                      "prop-name": "nume"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-3 my-auto" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Telefon")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newClientForm.telefon,
                        expression: "newClientForm.telefon"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      value: "",
                      disabled: _vm.isDisabled,
                      name: "telefon",
                      pattern: "[0-9]+([\\.,][0-9]+)?"
                    },
                    domProps: { value: _vm.newClientForm.telefon },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.newClientForm,
                          "telefon",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: {
                      "form-name": _vm.newClientForm,
                      "prop-name": "telefon"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-3 my-auto" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Email")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newClientForm.email,
                        expression: "newClientForm.email"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "email",
                      value: "",
                      disabled: _vm.isDisabled,
                      name: "email"
                    },
                    domProps: { value: _vm.newClientForm.email },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.newClientForm,
                          "email",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: {
                      "form-name": _vm.newClientForm,
                      "prop-name": "email"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-3 my-auto" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-success btn",
                  on: { click: _vm.okClientData }
                },
                [
                  _c("i", { staticClass: "fas fa-check" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "d-none d-sm-inline" }, [
                    _vm._v("OK")
                  ])
                ]
              )
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("section", { staticClass: "content m-3" }, [
      _c("div", { staticClass: "card border-bottom" }, [
        _c("div", { staticClass: "card-body" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
          _vm._v(" "),
          _c("div", { staticClass: "row mx-1" }, [
            _c("div", { staticClass: "col-12 col-sm-2 my-auto" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Latime")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newProductForm.latime,
                        expression: "newProductForm.latime"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      value: "",
                      name: "latime",
                      pattern: "[0-9]+([\\.,][0-9]+)?"
                    },
                    domProps: { value: _vm.newProductForm.latime },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.newProductForm,
                          "latime",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: {
                      "form-name": _vm.newProductForm,
                      "prop-name": "latime"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-2 my-auto" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Inaltime")]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.newProductForm.inaltime,
                        expression: "newProductForm.inaltime"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      value: "",
                      name: "inaltime",
                      pattern: "[0-9]+([\\.,][0-9]+)?"
                    },
                    domProps: { value: _vm.newProductForm.inaltime },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.newProductForm,
                          "inaltime",
                          $event.target.value
                        )
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: {
                      "form-name": _vm.newProductForm,
                      "prop-name": "inaltime"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-12 col-sm-3 my-auto" }, [
              _c(
                "div",
                { staticClass: "form-group" },
                [
                  _c("label", { attrs: { for: "" } }, [_vm._v("Culoare")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newProductForm.culoare,
                          expression: "newProductForm.culoare"
                        }
                      ],
                      staticClass: "form-control",
                      on: {
                        selected: function($event) {
                          return _vm.newProductForm.errors.clear("culoare")
                        },
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.newProductForm,
                            "culoare",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "", disabled: "" } }, [
                        _vm._v("Selectează o culoare..")
                      ]),
                      _vm._v(" "),
                      _c("option", [_vm._v("Alb")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("Maro deschis")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("Maro inchis")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("Gri antracit")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("Stejar auriu")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("Nuc")]),
                      _vm._v(" "),
                      _c("option", [_vm._v("Moorieche (Wenge)")])
                    ]
                  ),
                  _vm._v(" "),
                  _c("show-error", {
                    attrs: {
                      "form-name": _vm.newProductForm,
                      "prop-name": "culoare"
                    }
                  })
                ],
                1
              )
            ])
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v("\n        Calculator\n        "),
        _vm.todos
          ? _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(
                _vm._s(
                  _vm.trans("general.total_result_found", {
                    count: _vm.todos.total
                  })
                )
              )
            ])
          : _c("span", { staticClass: "card-subtitle" }, [
              _vm._v(_vm._s(_vm.trans("general.no_result_found")))
            ]),
        _vm._v(" "),
        _vm.todos.total && !_vm.showClientPanel
          ? _c(
              "button",
              {
                staticClass: "btn btn-info btn m-r-10",
                on: { click: _vm.addclient }
              },
              [
                _c("i", { staticClass: "fas fa-plus" }),
                _vm._v(" "),
                _c("span", { staticClass: "d-none d-sm-inline" }, [
                  _vm._v(_vm._s(_vm.trans("calculator.add_new_client")))
                ])
              ]
            )
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _vm.showProductPanel
      ? _c("section", { staticClass: "content" }, [
          _vm._m(3),
          _vm._v(" "),
          _vm._m(4)
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("section", { staticClass: "content" }, [
      _vm.showListPanel
        ? _c("div", { staticClass: "card" }, [
            _vm._m(5),
            _vm._v(" "),
            _vm._m(6)
          ])
        : _vm._e()
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c("transition", { attrs: { name: "fade" } }, [
              _vm.showClientPanel
                ? _c("div", { staticClass: "card border-bottom" }, [
                    _c("div", { staticClass: "card-body p-4" }, [
                      _c("h4", { staticClass: "card-title" }, [
                        _vm._v(_vm._s(_vm.trans("calculator.add_new_client")))
                      ]),
                      _vm._v(" "),
                      _c("form", [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12 col-sm-4" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.trans("calculator.nume")))
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.calcForm.nume,
                                      expression: "calcForm.nume"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    value: "",
                                    name: "nume",
                                    placeholder: _vm.trans("calculator.nume")
                                  },
                                  domProps: { value: _vm.calcForm.nume },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.calcForm,
                                        "nume",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.calcForm,
                                    "prop-name": "title"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-4" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.trans("calculator.tel")))
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.calcForm.tel,
                                      expression: "calcForm.tel"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    value: "",
                                    name: "tel",
                                    placeholder: _vm.trans("calculator.tel")
                                  },
                                  domProps: { value: _vm.calcForm.tel },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.calcForm,
                                        "tel",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.calcForm,
                                    "prop-name": "tel"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-12 col-sm-4" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v(_vm._s(_vm.trans("calculator.mail")))
                                ]),
                                _vm._v(" "),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.calcForm.mail,
                                      expression: "calcForm.mail"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "email",
                                    value: "",
                                    name: "mail",
                                    placeholder: _vm.trans("calculator.mail")
                                  },
                                  domProps: { value: _vm.calcForm.mail },
                                  on: {
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.calcForm,
                                        "mail",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.calcForm,
                                    "prop-name": "mail"
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-info waves-effect waves-light pull-right",
                            attrs: { type: "button" },
                            on: { click: _vm.salvare }
                          },
                          [
                            _vm.id
                              ? _c("span", [
                                  _vm._v(_vm._s(_vm.trans("calculator.update")))
                                ])
                              : _c("span", [
                                  _vm._v(_vm._s(_vm.trans("calculator.save")))
                                ])
                          ]
                        ),
                        _vm._v(" "),
                        !_vm.id
                          ? _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-danger waves-effect waves-light pull-right m-r-10",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    _vm.showClientPanel = !_vm.showClientPanel
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.trans("general.cancel")))]
                            )
                          : _vm._e()
                      ])
                    ])
                  ])
                : _vm._e()
            ])
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c("p", [_vm._v("de aici in jos")])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mx-1" }, [
      _c(
        "button",
        { staticClass: "btn btn-success m-2", attrs: { type: "button" } },
        [_vm._v("Plasa insecte balamale")]
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-success m-2", attrs: { type: "button" } },
        [_vm._v("Plasa insecte fixa")]
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-success m-2", attrs: { type: "button" } },
        [_vm._v("Plasa insecte rulou")]
      ),
      _vm._v(" "),
      _c(
        "button",
        { staticClass: "btn btn-success m-2", attrs: { type: "button" } },
        [_vm._v("Plasa insecte plisse")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mx-1" }, [
      _c("div", { staticClass: "col-12 col-sm-12 my-auto" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mx-1" }, [
      _c("h4", [_vm._v("Plasa insecte balamale")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h3", { staticClass: "card-title" }, [_vm._v("Plase tantari")])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "button",
              { staticClass: "btn btn-success", attrs: { type: "button" } },
              [_vm._v("Plasa insecte balamale")]
            ),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "btn btn-success", attrs: { type: "button" } },
              [_vm._v("Plasa insecte fixa")]
            ),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "btn btn-success", attrs: { type: "button" } },
              [_vm._v("Plasa insecte rulou")]
            ),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "btn btn-success", attrs: { type: "button" } },
              [_vm._v("Plasa insecte plisse")]
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-header" }, [
            _c("h3", { staticClass: "card-title" }, [
              _vm._v("Jaluzele Verticale")
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c(
              "button",
              { staticClass: "btn btn-success", attrs: { type: "button" } },
              [_vm._v("Jaluzele Verticale")]
            ),
            _vm._v(" "),
            _c(
              "button",
              { staticClass: "btn btn-success", attrs: { type: "button" } },
              [_vm._v("Material Jaluzele verticale")]
            )
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("Oferte")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-body p-0" }, [
      _c("table", { staticClass: "table table-striped projects" }, [
        _c("thead", [
          _c("tr", [
            _c("th", { staticStyle: { width: "1%" } }, [_vm._v("Data")]),
            _vm._v(" "),
            _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Client")]),
            _vm._v(" "),
            _c("th", { staticStyle: { width: "20%" } }, [
              _vm._v("Date de contact")
            ]),
            _vm._v(" "),
            _c("th", [_vm._v("Valoare")]),
            _vm._v(" "),
            _c(
              "th",
              { staticClass: "text-center", staticStyle: { width: "8%" } },
              [_vm._v("Status")]
            ),
            _vm._v(" "),
            _c("th", { staticStyle: { width: "20%" } })
          ])
        ]),
        _vm._v(" "),
        _c("tbody", [
          _c("tr", [
            _c("td", [_vm._v("#")]),
            _vm._v(" "),
            _c("td", [
              _c("a", [_vm._v("AdminLTE v3")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("small", [_vm._v("Created 01.01.2019")])
            ]),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td", { staticClass: "project_progress" }, [
              _c("div", { staticClass: "progress progress-sm" }, [
                _c("div", {
                  staticClass: "progress-bar bg-green",
                  staticStyle: { width: "57%" },
                  attrs: {
                    role: "progressbar",
                    "aria-volumenow": "57",
                    "aria-volumemin": "0",
                    "aria-volumemax": "100"
                  }
                })
              ]),
              _vm._v(" "),
              _c("small", [_vm._v("57% Complete")])
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "project-state" }, [
              _c("span", { staticClass: "badge badge-success" }, [
                _vm._v("Success")
              ])
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "project-actions text-right" }, [
              _c(
                "a",
                { staticClass: "btn btn-primary btn-sm", attrs: { href: "#" } },
                [
                  _c("i", { staticClass: "fas fa-folder" }),
                  _vm._v("\n                    View\n                  ")
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "btn btn-info btn-sm", attrs: { href: "#" } },
                [
                  _c("i", { staticClass: "fas fa-pencil-alt" }),
                  _vm._v("\n                    Edit\n                  ")
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "btn btn-danger btn-sm", attrs: { href: "#" } },
                [
                  _c("i", { staticClass: "fas fa-trash" }),
                  _vm._v("\n                    Delete\n                  ")
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("#")]),
            _vm._v(" "),
            _c("td", [
              _c("a", [_vm._v("AdminLTE v3")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("small", [_vm._v("Created 01.01.2019")])
            ]),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td", { staticClass: "project_progress" }, [
              _c("div", { staticClass: "progress progress-sm" }, [
                _c("div", {
                  staticClass: "progress-bar bg-green",
                  staticStyle: { width: "47%" },
                  attrs: {
                    role: "progressbar",
                    "aria-volumenow": "47",
                    "aria-volumemin": "0",
                    "aria-volumemax": "100"
                  }
                })
              ]),
              _vm._v(" "),
              _c("small", [_vm._v("47% Complete")])
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "project-state" }, [
              _c("span", { staticClass: "badge badge-success" }, [
                _vm._v("Success")
              ])
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "project-actions text-right" }, [
              _c(
                "a",
                { staticClass: "btn btn-primary btn-sm", attrs: { href: "#" } },
                [
                  _c("i", { staticClass: "fas fa-folder" }),
                  _vm._v("\n                    View\n                  ")
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "btn btn-info btn-sm", attrs: { href: "#" } },
                [
                  _c("i", { staticClass: "fas fa-pencil-alt" }),
                  _vm._v("\n                    Edit\n                  ")
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "btn btn-danger btn-sm", attrs: { href: "#" } },
                [
                  _c("i", { staticClass: "fas fa-trash" }),
                  _vm._v("\n                    Delete\n                  ")
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", [_vm._v("#")]),
            _vm._v(" "),
            _c("td", [
              _c("a", [_vm._v("AdminLTE v3")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c("small", [_vm._v("Created 01.01.2019")])
            ]),
            _vm._v(" "),
            _c("td"),
            _vm._v(" "),
            _c("td", { staticClass: "project_progress" }, [
              _c("div", { staticClass: "progress progress-sm" }, [
                _c("div", {
                  staticClass: "progress-bar bg-green",
                  staticStyle: { width: "77%" },
                  attrs: {
                    role: "progressbar",
                    "aria-volumenow": "77",
                    "aria-volumemin": "0",
                    "aria-volumemax": "100"
                  }
                })
              ]),
              _vm._v(" "),
              _c("small", [_vm._v("77% Complete")])
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "project-state" }, [
              _c("span", { staticClass: "badge badge-success" }, [
                _vm._v("Success")
              ])
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "project-actions text-right" }, [
              _c(
                "a",
                { staticClass: "btn btn-primary btn-sm", attrs: { href: "#" } },
                [
                  _c("i", { staticClass: "fas fa-folder" }),
                  _vm._v("\n                    View\n                  ")
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "btn btn-info btn-sm", attrs: { href: "#" } },
                [
                  _c("i", { staticClass: "fas fa-pencil-alt" }),
                  _vm._v("\n                    Edit\n                  ")
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                { staticClass: "btn btn-danger btn-sm", attrs: { href: "#" } },
                [
                  _c("i", { staticClass: "fas fa-trash" }),
                  _vm._v("\n                    Delete\n                  ")
                ]
              )
            ])
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }