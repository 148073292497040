<template>
    <form>

        <div class="row">
            <div class="col-12 col-sm-4">
                <div class="form-group">
                    <label for="">{{trans('calculator.nume')}}</label>
                    <input class="form-control" type="text" value="" v-model="calcForm.nume" name="nume" :placeholder="trans('calculator.nume')">
                    <show-error :form-name="calcForm" prop-name="title"></show-error>
                </div>
            </div>
            <div class="col-12 col-sm-4">
                <div class="form-group">
                    <label for="">{{trans('calculator.tel')}}</label>
                    <input class="form-control" type="text" value="" v-model="calcForm.tel" name=tel :placeholder="trans('calculator.tel')">
                    <show-error :form-name="calcForm" prop-name="tel"></show-error>
                </div>
            </div>

            <div class="col-12 col-sm-4">
                <div class="form-group">
                    <label for="">{{trans('calculator.mail')}}</label>
                    <input class="form-control" type="email" value="" v-model="calcForm.mail" name=mail :placeholder="trans('calculator.mail')">
                    <show-error :form-name="calcForm" prop-name="mail"></show-error>
                </div>
            </div>



        </div>
        <button type="button" class="btn btn-info waves-effect waves-light pull-right" @click="$emit('ok')">
            <span v-if="id">{{trans('calculator.update')}}</span>
            <span v-else>{{trans('calculator.save')}}</span>
        </button>
        <!-- <router-link to="/calculator" class="btn btn-danger waves-effect waves-light pull-right m-r-10" v-show="id">{{trans('general.cancel')}}</router-link> -->
        <button v-if="!id" type="button" class="btn btn-danger waves-effect waves-light pull-right m-r-10" @click="$emit('cancel')">{{trans('general.cancel')}}</button>
    </form>
</template>


<script>
    import datepicker from 'vuejs-datepicker'
    import autosizeTextarea from '../../components/autosize-textarea'

    export default {
        components: {datepicker,autosizeTextarea},
        data() {
            return {
                calcForm: new Form({
                    nume : '',
                    tel : '',
                    mail: ''
                })
            };
        },
        props: ['id'],
        mounted() {

        },
        methods: {
        }
    }
</script>
