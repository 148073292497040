var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "invoice" } }, [
    _c("div", { staticClass: "toolbar hidden-print" }, [
      _c("div", { staticClass: "text-right" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-info",
            attrs: { id: "printInvoice" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.print("invoice")
              }
            }
          },
          [_c("i", { staticClass: "fa fa-print" }), _vm._v(" Print")]
        ),
        _vm._v(" "),
        _vm._m(0)
      ]),
      _vm._v(" "),
      _c("hr")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "invoice overflow-auto" }, [
      _c("div", { staticStyle: { "min-width": "600px" } }, [
        _vm._m(1),
        _vm._v(" "),
        _c("main", [
          _c("div", { staticClass: "row contacts" }, [
            _c("div", { staticClass: "col invoice-to" }, [
              _c("div", { staticClass: "text-gray-light" }, [
                _vm._v("Beneficiar:")
              ]),
              _vm._v(" "),
              _c("h2", { staticClass: "to" }, [
                _vm._v(_vm._s(_vm.printStore.clientName))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "address" }, [
                _vm._v(
                  _vm._s(_vm.printStore.judDom) +
                    ", Strada: " +
                    _vm._s(_vm.printStore.clientStrDom) +
                    ", Numar: " +
                    _vm._s(_vm.printStore.clientNrStrDom) +
                    ", " +
                    _vm._s(_vm.printStore.clientSectLocDom)
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "email" }, [
                _vm._v(_vm._s(_vm.printStore.clientMail))
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col invoice-details" }, [
              _c("h1", { staticClass: "invoice-id" }, [
                _vm._v("Proforma nr. " + _vm._s(_vm.printStore.nrCtr))
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "date" }, [
                _vm._v("Data: " + _vm._s(_vm.printStore.dataCtr))
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "table",
            { attrs: { border: "0", cellspacing: "0", cellpadding: "0" } },
            [
              _vm._m(2),
              _vm._v(" "),
              _c("tbody", [
                _c("tr", [
                  _c("td", { staticClass: "no" }, [_vm._v("01")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-left" }, [
                    _c("h3", [
                      _vm._v(
                        "\r\n                                " +
                          _vm._s(_vm.printStore.tipProdus) +
                          "\r\n                               "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "unit" }, [_vm._v("Buc.")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "qty" }, [_vm._v("1")]),
                  _vm._v(" "),
                  _c("td", { staticClass: "total" }, [
                    _vm._v(_vm._s(_vm.printStore.ValRON))
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("tfoot", [
                _c("tr", [
                  _c("td", { attrs: { colspan: "2" } }),
                  _vm._v(" "),
                  _c("td", { attrs: { colspan: "2" } }, [_vm._v("TOTAL")]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(_vm.printStore.ValRON) + " lei")])
                ])
              ])
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "thanks" }),
          _vm._v(" "),
          _c("div", { staticClass: "thanks" }, [
            _vm._v("Multumim pentru incredere!")
          ]),
          _vm._v(" "),
          _vm._m(3)
        ]),
        _vm._v(" "),
        _c("footer", [
          _vm._v(
            "\r\n                Documentul a fost generat cu mijloace informatice. Circula fara semnatura si stampila.\r\n            "
          )
        ])
      ]),
      _vm._v(" "),
      _c("div")
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("button", { staticClass: "btn btn-info" }, [
      _c("i", { staticClass: "fa fa-file-pdf-o" }),
      _vm._v(" Export as PDF")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _c(
            "a",
            {
              attrs: {
                target: "_blank",
                href: "https://jaluzele-bucuresti.ro/"
              }
            },
            [
              _c("img", {
                attrs: {
                  src:
                    "https://jaluzele-bucuresti.ro/wp-content/uploads/2020/01/logo-jaluzele-bucuresti.png",
                  "data-holder-rendered": "true"
                }
              })
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col company-details" }, [
          _c("h2", { staticClass: "name" }, [
            _c(
              "a",
              {
                attrs: {
                  target: "_blank",
                  href: "https://jaluzele-bucuresti.ro/"
                }
              },
              [
                _vm._v(
                  "\r\n                            Jaluzele Bucuresti\r\n                            "
                )
              ]
            )
          ]),
          _vm._v(" "),
          _c("div", [_vm._v("Bucuresti")]),
          _vm._v(" "),
          _c("div", [_vm._v("(123) 456-789")]),
          _vm._v(" "),
          _c("div", [_vm._v("comenzi@jaluzele-bucuresti.ro")])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("#")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-left" }, [_vm._v("Denumire")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right" }, [_vm._v("U.M.")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right" }, [_vm._v("Cantitate")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-right" }, [_vm._v("Pret (lei)")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "notices" }, [
      _c("div", [_vm._v("Cont:")]),
      _vm._v(" "),
      _c("div", { staticClass: "notice" }, [
        _vm._v("Factura se poate achita in contul")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "notice" }, [
        _vm._v("IBAN: 1212 1122 11111 11111 1111 ")
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "notice" }, [_vm._v("Banca: Nume Banca ")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }