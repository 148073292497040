var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("section", { staticClass: "content m-3" }, [
      _c("div", { staticClass: "card border-bottom" }, [
        _c("div", { staticClass: "card-body" }, [
          _c("div", { staticClass: "row mx-1" }, [
            _c("div", { staticClass: "col-12 col-sm-2 my-auto" }, [
              _vm.showAddCtr == false
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-success btn m-r-10",
                      on: { click: _vm.addCtrClick }
                    },
                    [
                      _c("i", { staticClass: "fas fa-plus" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "d-none d-sm-inline" }, [
                        _vm._v("Contract nou")
                      ])
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showAddCtr == true
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-danger btn m-r-10",
                      on: { click: _vm.cancel }
                    },
                    [
                      _c("i", { staticClass: "fas fa-times" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "d-none d-sm-inline" }, [
                        _vm._v("Anulare")
                      ])
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm.showAddCtr == false
              ? _c("div", { staticClass: "col-12 col-sm-4" }, [
                  _c("div", { staticClass: "form-group" }, [
                    _c("label", { attrs: { for: "" } }, [_vm._v("Cauta")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filterContractForm.keyword,
                          expression: "filterContractForm.keyword"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        name: "keyword",
                        placeholder: "Nume, Numar contract sau Telefon"
                      },
                      domProps: { value: _vm.filterContractForm.keyword },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.filterContractForm,
                            "keyword",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ])
              : _vm._e()
          ])
        ])
      ])
    ]),
    _vm._v(" "),
    _vm.showAddCtr == true
      ? _c("section", { staticClass: "content m-3" }, [
          _c("div", { staticClass: "card" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "card-body" }, [
              _c(
                "form",
                {
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.proceed($event)
                    },
                    completed: _vm.getContracts,
                    keydown: function($event) {
                      return _vm.ctrForm.errors.clear($event.target.name)
                    }
                  }
                },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mx-1" }, [
                    _c("div", { staticClass: "col-12 col-sm-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Numar contract")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ctrForm.nrCtr,
                                expression: "ctrForm.nrCtr"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              type: "text",
                              name: "nrCtr",
                              disabled: ""
                            },
                            domProps: { value: _vm.ctrForm.nrCtr },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.ctrForm,
                                  "nrCtr",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.ctrForm,
                              "prop-name": "nrCtr"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-sm-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [_vm._v("Data")]),
                          _vm._v(" "),
                          _c("datepicker", {
                            attrs: { bootstrapStyling: true, disabled: "" },
                            on: {
                              selected: function($event) {
                                return _vm.ctrForm.errors.clear("dataCtr")
                              }
                            },
                            model: {
                              value: _vm.ctrForm.dataCtr,
                              callback: function($$v) {
                                _vm.$set(_vm.ctrForm, "dataCtr", $$v)
                              },
                              expression: "ctrForm.dataCtr"
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.ctrForm,
                              "prop-name": "dataCtr"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mx-1" }, [
                    _c("div", { staticClass: "col-12 col-sm-4" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Tip contract")
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.ctrForm.tipProdus,
                                  expression: "ctrForm.tipProdus"
                                }
                              ],
                              staticClass: "form-control",
                              on: {
                                selected: function($event) {
                                  return _vm.ctrForm.errors.clear("tipProdus")
                                },
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.ctrForm,
                                    "tipProdus",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c("option", [_vm._v("Tamplarie PVC")]),
                              _vm._v(" "),
                              _c("option", [_vm._v("Usi de interior")]),
                              _vm._v(" "),
                              _c("option", [_vm._v("Jaluzele")]),
                              _vm._v(" "),
                              _c("option", [_vm._v("Accesorii")])
                            ]
                          ),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.ctrForm,
                              "prop-name": "tipProdus"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-sm-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Valoare EURO")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ctrForm.ValEuro,
                                expression: "ctrForm.ValEuro"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "ValEuro",
                              pattern: "[0-9]+([\\.,][0-9]+)?",
                              type: "number",
                              name: "ValEuro"
                            },
                            domProps: { value: _vm.ctrForm.ValEuro },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.ctrForm,
                                    "ValEuro",
                                    $event.target.value
                                  )
                                },
                                _vm.exchangeEuro
                              ],
                              change: _vm.exchangeEuro
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.ctrForm,
                              "prop-name": "ValEuro"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-sm-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Valoare LEI")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ctrForm.ValRON,
                                expression: "ctrForm.ValRON"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "ValRON",
                              pattern: "[0-9]+([\\.,][0-9]+)?",
                              type: "number",
                              name: "ValRON",
                              disabled: ""
                            },
                            domProps: { value: _vm.ctrForm.ValRON },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.ctrForm,
                                  "ValRON",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.ctrForm,
                              "prop-name": "ValRON"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-12 col-sm-2" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("CURS BNR")
                          ]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ctrForm.cursBNR,
                                expression: "ctrForm.cursBNR"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "cursBNR",
                              pattern: "[0-9]+([\\.,][0-9]+)?",
                              type: "number",
                              name: "cursBNR",
                              disabled: ""
                            },
                            domProps: { value: _vm.ctrForm.cursBNR },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.ctrForm,
                                  "cursBNR",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.ctrForm,
                              "prop-name": "cursBNR"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mx-1" }, [
                    _c("div", { staticClass: "col-12 col-sm-3" }, [
                      _c("label", { attrs: { for: "" } }, [
                        _vm._v("Tip Client")
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "input-group mb-0" }, [
                        _c("div", { staticClass: "mx-auto" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.pfValue,
                                expression: "pfValue"
                              }
                            ],
                            staticClass: "d-inline",
                            attrs: {
                              type: "radio",
                              name: "tipcl",
                              id: "pfizica",
                              value: "pf",
                              checked: ""
                            },
                            domProps: { checked: _vm._q(_vm.pfValue, "pf") },
                            on: {
                              change: [
                                function($event) {
                                  _vm.pfValue = "pf"
                                },
                                function($event) {
                                  return _vm.checkTipClient("pf")
                                }
                              ]
                            }
                          }),
                          _vm._v("Persoana fizica\n                ")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "mx-auto" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.pfValue,
                                expression: "pfValue"
                              }
                            ],
                            staticClass: "d-inline",
                            attrs: {
                              type: "radio",
                              name: "tipcl",
                              id: "pjuridica",
                              value: "pj"
                            },
                            domProps: { checked: _vm._q(_vm.pfValue, "pj") },
                            on: {
                              change: [
                                function($event) {
                                  _vm.pfValue = "pj"
                                },
                                function($event) {
                                  return _vm.checkTipClient("pj")
                                }
                              ]
                            }
                          }),
                          _vm._v("Persoana juridica\n                ")
                        ])
                      ])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mx-1" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-4" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Denumire Client")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group mb-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ctrForm.clientName,
                                expression: "ctrForm.clientName"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "clientName",
                              type: "text",
                              name: "clientName"
                            },
                            domProps: { value: _vm.ctrForm.clientName },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.ctrForm,
                                    "clientName",
                                    $event.target.value
                                  )
                                },
                                function($event) {
                                  _vm.ctrForm.clientName = _vm.ctrForm.clientName.toUpperCase()
                                }
                              ]
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "input-group-append" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-info btn-flat",
                                attrs: { type: "button" },
                                on: {
                                  click: function($event) {
                                    return _vm.searchName(
                                      _vm.ctrForm.clientName
                                    )
                                  }
                                }
                              },
                              [_c("i", { staticClass: "fas fa-search" })]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.ctrForm,
                            "prop-name": "clientName"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-2" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Telefon")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group mb-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ctrForm.clientPhone,
                                expression: "ctrForm.clientPhone"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "clientPhone",
                              pattern: "[0-9]+([\\.,][0-9]+)?",
                              type: "number",
                              name: "clientPhone"
                            },
                            domProps: { value: _vm.ctrForm.clientPhone },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.ctrForm,
                                  "clientPhone",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "input-group-append" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-info btn-flat",
                                attrs: { type: "button" },
                                on: { click: _vm.searchPhone }
                              },
                              [_c("i", { staticClass: "fas fa-search" })]
                            )
                          ])
                        ]),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.ctrForm,
                            "prop-name": "clientPhone"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-2" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Telefon alternativ")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group mb-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ctrForm.clientPhone2,
                                expression: "ctrForm.clientPhone2"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "clientPhone2",
                              pattern: "[0-9]+([\\.,][0-9]+)?",
                              type: "number",
                              name: "clientPhone2"
                            },
                            domProps: { value: _vm.ctrForm.clientPhone2 },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.ctrForm,
                                  "clientPhone2",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.ctrForm,
                            "prop-name": "clientPhone2"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-3" },
                      [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Email")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group mb-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ctrForm.clientMail,
                                expression: "ctrForm.clientMail"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "clientMail",
                              type: "email",
                              name: "clientMail"
                            },
                            domProps: { value: _vm.ctrForm.clientMail },
                            on: {
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.ctrForm,
                                  "clientMail",
                                  $event.target.value
                                )
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.ctrForm,
                            "prop-name": "clientMail"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.pfValue == "pj"
                    ? _c("div", { staticClass: "row mx-1" }, [
                        _c("div", { staticClass: "col-12 col-sm-2" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Cod Unic Inregistrare")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "input-group mb-0" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.ctrForm.codUnicPJ,
                                    expression: "ctrForm.codUnicPJ"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "codUnicPJ",
                                  type: "text",
                                  name: "codUnicPJ"
                                },
                                domProps: { value: _vm.ctrForm.codUnicPJ },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.ctrForm,
                                        "codUnicPJ",
                                        $event.target.value
                                      )
                                    },
                                    function($event) {
                                      _vm.ctrForm.codUnicPJ = _vm.ctrForm.codUnicPJ.toUpperCase()
                                    }
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.ctrForm,
                                  "prop-name": "codUnicPJ"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-2" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Nr. Reg. Comertului")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "input-group mb-0" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.ctrForm.regCom,
                                    expression: "ctrForm.regCom"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "regCom",
                                  type: "text",
                                  name: "regCom"
                                },
                                domProps: { value: _vm.ctrForm.regCom },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.ctrForm,
                                        "regCom",
                                        $event.target.value
                                      )
                                    },
                                    function($event) {
                                      _vm.ctrForm.regCom = _vm.ctrForm.regCom.toUpperCase()
                                    }
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.ctrForm,
                                  "prop-name": "regCom"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-12 col-sm-2" }, [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Reprezentant")
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "input-group mb-0" },
                            [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.ctrForm.reprezentant,
                                    expression: "ctrForm.reprezentant"
                                  }
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "reprezentant",
                                  type: "text",
                                  name: "reprezentant"
                                },
                                domProps: { value: _vm.ctrForm.reprezentant },
                                on: {
                                  input: [
                                    function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.ctrForm,
                                        "reprezentant",
                                        $event.target.value
                                      )
                                    },
                                    function($event) {
                                      _vm.ctrForm.reprezentant = _vm.ctrForm.reprezentant.toUpperCase()
                                    }
                                  ]
                                }
                              }),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.ctrForm,
                                  "prop-name": "reprezentant"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "row m-3" }, [
                    _c("div", { staticClass: "col-12 col-sm-3" }, [
                      _vm.pfValue == "pf"
                        ? _c("h4", [_vm._v("Adresa domiciliu")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.pfValue == "pj"
                        ? _c("h4", [_vm._v("Sediu social")])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mx-1" }, [
                    _c("div", { staticClass: "col-12 col-sm-3" }, [
                      _c(
                        "div",
                        { staticClass: "form-group" },
                        [
                          _c("label", { attrs: { for: "" } }, [
                            _vm._v("Judet")
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.ctrForm.judDom,
                                  expression: "ctrForm.judDom"
                                }
                              ],
                              staticClass: "form-control",
                              on: {
                                selected: function($event) {
                                  return _vm.ctrForm.errors.clear("judDom")
                                },
                                change: function($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function(o) {
                                      return o.selected
                                    })
                                    .map(function(o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.$set(
                                    _vm.ctrForm,
                                    "judDom",
                                    $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  )
                                }
                              }
                            },
                            [
                              _c(
                                "option",
                                { attrs: { value: "", disabled: "" } },
                                [_vm._v("Selectează o opțiune…")]
                              ),
                              _vm._v(" "),
                              _c(
                                "option",
                                { attrs: { value: "B", selected: "" } },
                                [_vm._v("București")]
                              ),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "IF" } }, [
                                _vm._v("Ilfov")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "AB" } }, [
                                _vm._v("Alba")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "AR" } }, [
                                _vm._v("Arad")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "AG" } }, [
                                _vm._v("Argeș")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "BC" } }, [
                                _vm._v("Bacău")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "BH" } }, [
                                _vm._v("Bihor")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "BN" } }, [
                                _vm._v("Bistrița-Năsăud")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "BT" } }, [
                                _vm._v("Botoșani")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "BR" } }, [
                                _vm._v("Brăila")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "BV" } }, [
                                _vm._v("Brașov")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "BZ" } }, [
                                _vm._v("Buzău")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "CL" } }, [
                                _vm._v("Călărași")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "CS" } }, [
                                _vm._v("Caraș-Severin")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "CJ" } }, [
                                _vm._v("Cluj")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "CT" } }, [
                                _vm._v("Constanța")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "CV" } }, [
                                _vm._v("Covasna")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "DB" } }, [
                                _vm._v("Dâmbovița")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "DJ" } }, [
                                _vm._v("Dolj")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "GL" } }, [
                                _vm._v("Galați")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "GR" } }, [
                                _vm._v("Giurgiu")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "GJ" } }, [
                                _vm._v("Gorj")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "HR" } }, [
                                _vm._v("Harghita")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "HD" } }, [
                                _vm._v("Hunedoara")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "IL" } }, [
                                _vm._v("Ialomița")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "IS" } }, [
                                _vm._v("Iași")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "MM" } }, [
                                _vm._v("Maramureș")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "MH" } }, [
                                _vm._v("Mehedinți")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "MS" } }, [
                                _vm._v("Mureș")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "NT" } }, [
                                _vm._v("Neamț")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "OT" } }, [
                                _vm._v("Olt")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "PH" } }, [
                                _vm._v("Prahova")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "SJ" } }, [
                                _vm._v("Sălaj")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "SM" } }, [
                                _vm._v("Satu Mare")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "SB" } }, [
                                _vm._v("Sibiu")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "SV" } }, [
                                _vm._v("Suceava")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "TR" } }, [
                                _vm._v("Teleorman")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "TM" } }, [
                                _vm._v("Timiș")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "TL" } }, [
                                _vm._v("Tulcea")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "VL" } }, [
                                _vm._v("Vâlcea")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "VS" } }, [
                                _vm._v("Vaslui")
                              ]),
                              _vm._v(" "),
                              _c("option", { attrs: { value: "VN" } }, [
                                _vm._v("Vrancea")
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c("show-error", {
                            attrs: {
                              "form-name": _vm.ctrForm,
                              "prop-name": "judDom"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-3" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Sector / Localitate")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group mb-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ctrForm.clientSectLocDom,
                                expression: "ctrForm.clientSectLocDom"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "clientSectLocDom",
                              type: "text",
                              name: "clientSectLocDom"
                            },
                            domProps: { value: _vm.ctrForm.clientSectLocDom },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.ctrForm,
                                    "clientSectLocDom",
                                    $event.target.value
                                  )
                                },
                                function($event) {
                                  _vm.ctrForm.clientSectLocDom = _vm.ctrForm.clientSectLocDom.toUpperCase()
                                }
                              ]
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.ctrForm,
                            "prop-name": "clientSectLocDom"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-4" },
                      [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Strada")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group mb-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ctrForm.clientStrDom,
                                expression: "ctrForm.clientStrDom"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "clientStrDom",
                              type: "text",
                              name: "clientStrDom"
                            },
                            domProps: { value: _vm.ctrForm.clientStrDom },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.ctrForm,
                                    "clientStrDom",
                                    $event.target.value
                                  )
                                },
                                function($event) {
                                  _vm.ctrForm.clientStrDom = _vm.ctrForm.clientStrDom.toUpperCase()
                                }
                              ]
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.ctrForm,
                            "prop-name": "clientStrDom"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-2" },
                      [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Numar")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group mb-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ctrForm.clientNrStrDom,
                                expression: "ctrForm.clientNrStrDom"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "clientNrStrDom",
                              type: "text",
                              name: "clientNrStrDom"
                            },
                            domProps: { value: _vm.ctrForm.clientNrStrDom },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.ctrForm,
                                    "clientNrStrDom",
                                    $event.target.value
                                  )
                                },
                                function($event) {
                                  _vm.ctrForm.clientNrStrDom = _vm.ctrForm.clientNrStrDom.toUpperCase()
                                }
                              ]
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.ctrForm,
                            "prop-name": "clientNrStrDom"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row mx-1" }, [
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-2" },
                      [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Bloc")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group mb-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ctrForm.clientBlocDom,
                                expression: "ctrForm.clientBlocDom"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "clientBlocDom",
                              type: "text",
                              name: "clientBlocDom"
                            },
                            domProps: { value: _vm.ctrForm.clientBlocDom },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.ctrForm,
                                    "clientBlocDom",
                                    $event.target.value
                                  )
                                },
                                function($event) {
                                  _vm.ctrForm.clientBlocDom = _vm.ctrForm.clientBlocDom.toUpperCase()
                                }
                              ]
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.ctrForm,
                            "prop-name": "clientBlocDom"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-2" },
                      [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Scara")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group mb-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ctrForm.clientScaraDom,
                                expression: "ctrForm.clientScaraDom"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "clientScaraDom",
                              type: "text",
                              name: "clientScaraDom"
                            },
                            domProps: { value: _vm.ctrForm.clientScaraDom },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.ctrForm,
                                    "clientScaraDom",
                                    $event.target.value
                                  )
                                },
                                function($event) {
                                  _vm.ctrForm.clientScaraDom = _vm.ctrForm.clientScaraDom.toUpperCase()
                                }
                              ]
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.ctrForm,
                            "prop-name": "clientScaraDom"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-2" },
                      [
                        _c("label", { attrs: { for: "" } }, [_vm._v("Etaj")]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group mb-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ctrForm.clientEtajDom,
                                expression: "ctrForm.clientEtajDom"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "clientEtajDom",
                              type: "text",
                              name: "clientEtajDom"
                            },
                            domProps: { value: _vm.ctrForm.clientEtajDom },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.ctrForm,
                                    "clientEtajDom",
                                    $event.target.value
                                  )
                                },
                                function($event) {
                                  _vm.ctrForm.clientEtajDom = _vm.ctrForm.clientEtajDom.toUpperCase()
                                }
                              ]
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.ctrForm,
                            "prop-name": "clientEtajDom"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-2" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Apartament")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group mb-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ctrForm.clientApDom,
                                expression: "ctrForm.clientApDom"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "clientApDom",
                              type: "text",
                              name: "clientApDom"
                            },
                            domProps: { value: _vm.ctrForm.clientApDom },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.ctrForm,
                                    "clientApDom",
                                    $event.target.value
                                  )
                                },
                                function($event) {
                                  _vm.ctrForm.clientApDom = _vm.ctrForm.clientApDom.toUpperCase()
                                }
                              ]
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.ctrForm,
                            "prop-name": "clientApDom"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-2" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Interfon")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group mb-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ctrForm.clientIntfDom,
                                expression: "ctrForm.clientIntfDom"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "clientIntfDom",
                              type: "text",
                              name: "clientIntfDom"
                            },
                            domProps: { value: _vm.ctrForm.clientIntfDom },
                            on: {
                              input: [
                                function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.ctrForm,
                                    "clientIntfDom",
                                    $event.target.value
                                  )
                                },
                                function($event) {
                                  _vm.ctrForm.clientIntfDom = _vm.ctrForm.clientIntfDom.toUpperCase()
                                }
                              ]
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.ctrForm,
                            "prop-name": "clientIntfDom"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-12 col-sm-2" },
                      [
                        _c("label", { attrs: { for: "" } }, [
                          _vm._v("Adresa montaj diferita")
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "input-group mb-0" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.ctrForm.clientAdresaMCheck,
                                expression: "ctrForm.clientAdresaMCheck"
                              }
                            ],
                            staticClass: "form-control",
                            attrs: {
                              id: "clientAdresaMCheck",
                              type: "checkbox",
                              value: "true",
                              name: "clientAdresaMCheck",
                              unchecked: ""
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.ctrForm.clientAdresaMCheck
                              )
                                ? _vm._i(
                                    _vm.ctrForm.clientAdresaMCheck,
                                    "true"
                                  ) > -1
                                : _vm.ctrForm.clientAdresaMCheck
                            },
                            on: {
                              change: function($event) {
                                var $$a = _vm.ctrForm.clientAdresaMCheck,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = "true",
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.ctrForm,
                                        "clientAdresaMCheck",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.ctrForm,
                                        "clientAdresaMCheck",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.ctrForm,
                                    "clientAdresaMCheck",
                                    $$c
                                  )
                                }
                              }
                            }
                          })
                        ]),
                        _vm._v(" "),
                        _c("show-error", {
                          attrs: {
                            "form-name": _vm.ctrForm,
                            "prop-name": "clientAdresaMCheck"
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _vm.ctrForm.clientAdresaMCheck == "1"
                    ? _c("div", [
                        _vm._m(2),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mx-1" }, [
                          _c("div", { staticClass: "col-12 col-sm-3" }, [
                            _c(
                              "div",
                              { staticClass: "form-group" },
                              [
                                _c("label", { attrs: { for: "" } }, [
                                  _vm._v("Judet")
                                ]),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.ctrForm.judMnt,
                                        expression: "ctrForm.judMnt"
                                      }
                                    ],
                                    staticClass: "form-control",
                                    attrs: { id: "judMnt", name: "judMnt" },
                                    on: {
                                      selected: function($event) {
                                        return _vm.ctrForm.errors.clear(
                                          "judMnt"
                                        )
                                      },
                                      change: function($event) {
                                        var $$selectedVal = Array.prototype.filter
                                          .call($event.target.options, function(
                                            o
                                          ) {
                                            return o.selected
                                          })
                                          .map(function(o) {
                                            var val =
                                              "_value" in o ? o._value : o.value
                                            return val
                                          })
                                        _vm.$set(
                                          _vm.ctrForm,
                                          "judMnt",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "option",
                                      { attrs: { value: "", disabled: "" } },
                                      [_vm._v("Selectează o opțiune…")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "option",
                                      { attrs: { value: "B", selected: "" } },
                                      [_vm._v("București")]
                                    ),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "IF" } }, [
                                      _vm._v("Ilfov")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "AB" } }, [
                                      _vm._v("Alba")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "AR" } }, [
                                      _vm._v("Arad")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "AG" } }, [
                                      _vm._v("Argeș")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "BC" } }, [
                                      _vm._v("Bacău")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "BH" } }, [
                                      _vm._v("Bihor")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "BN" } }, [
                                      _vm._v("Bistrița-Năsăud")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "BT" } }, [
                                      _vm._v("Botoșani")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "BR" } }, [
                                      _vm._v("Brăila")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "BV" } }, [
                                      _vm._v("Brașov")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "BZ" } }, [
                                      _vm._v("Buzău")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "CL" } }, [
                                      _vm._v("Călărași")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "CS" } }, [
                                      _vm._v("Caraș-Severin")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "CJ" } }, [
                                      _vm._v("Cluj")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "CT" } }, [
                                      _vm._v("Constanța")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "CV" } }, [
                                      _vm._v("Covasna")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "DB" } }, [
                                      _vm._v("Dâmbovița")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "DJ" } }, [
                                      _vm._v("Dolj")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "GL" } }, [
                                      _vm._v("Galați")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "GR" } }, [
                                      _vm._v("Giurgiu")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "GJ" } }, [
                                      _vm._v("Gorj")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "HR" } }, [
                                      _vm._v("Harghita")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "HD" } }, [
                                      _vm._v("Hunedoara")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "IL" } }, [
                                      _vm._v("Ialomița")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "IS" } }, [
                                      _vm._v("Iași")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "MM" } }, [
                                      _vm._v("Maramureș")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "MH" } }, [
                                      _vm._v("Mehedinți")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "MS" } }, [
                                      _vm._v("Mureș")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "NT" } }, [
                                      _vm._v("Neamț")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "OT" } }, [
                                      _vm._v("Olt")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "PH" } }, [
                                      _vm._v("Prahova")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "SJ" } }, [
                                      _vm._v("Sălaj")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "SM" } }, [
                                      _vm._v("Satu Mare")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "SB" } }, [
                                      _vm._v("Sibiu")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "SV" } }, [
                                      _vm._v("Suceava")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "TR" } }, [
                                      _vm._v("Teleorman")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "TM" } }, [
                                      _vm._v("Timiș")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "TL" } }, [
                                      _vm._v("Tulcea")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "VL" } }, [
                                      _vm._v("Vâlcea")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "VS" } }, [
                                      _vm._v("Vaslui")
                                    ]),
                                    _vm._v(" "),
                                    _c("option", { attrs: { value: "VN" } }, [
                                      _vm._v("Vrancea")
                                    ])
                                  ]
                                ),
                                _vm._v(" "),
                                _c("show-error", {
                                  attrs: {
                                    "form-name": _vm.ctrForm,
                                    "prop-name": "judMnt"
                                  }
                                })
                              ],
                              1
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-12 col-sm-3" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Sector / Localitate")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "input-group mb-0" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.ctrForm.clientSectLocMnt,
                                      expression: "ctrForm.clientSectLocMnt"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "clientSectLocMnt",
                                    type: "text",
                                    name: "clientSectLocMnt"
                                  },
                                  domProps: {
                                    value: _vm.ctrForm.clientSectLocMnt
                                  },
                                  on: {
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.ctrForm,
                                          "clientSectLocMnt",
                                          $event.target.value
                                        )
                                      },
                                      function($event) {
                                        _vm.ctrForm.clientSectLocMnt = _vm.ctrForm.clientSectLocMnt.toUpperCase()
                                      }
                                    ]
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.ctrForm,
                                  "prop-name": "clientSectLocMnt"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-12 col-sm-4" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Strada")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "input-group mb-0" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.ctrForm.clientStrMnt,
                                      expression: "ctrForm.clientStrMnt"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "clientStrMnt",
                                    type: "text",
                                    name: "clientStrMnt"
                                  },
                                  domProps: { value: _vm.ctrForm.clientStrMnt },
                                  on: {
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.ctrForm,
                                          "clientStrMnt",
                                          $event.target.value
                                        )
                                      },
                                      function($event) {
                                        _vm.ctrForm.clientStrMnt = _vm.ctrForm.clientStrMnt.toUpperCase()
                                      }
                                    ]
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.ctrForm,
                                  "prop-name": "clientStrMnt"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-12 col-sm-2" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Numar")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "input-group mb-0" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.ctrForm.clientNrStrMnt,
                                      expression: "ctrForm.clientNrStrMnt"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "clientNrStrMnt",
                                    type: "text",
                                    name: "clientNrStrMnt"
                                  },
                                  domProps: {
                                    value: _vm.ctrForm.clientNrStrMnt
                                  },
                                  on: {
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.ctrForm,
                                          "clientNrStrMnt",
                                          $event.target.value
                                        )
                                      },
                                      function($event) {
                                        _vm.ctrForm.clientNrStrMnt = _vm.ctrForm.clientNrStrMnt.toUpperCase()
                                      }
                                    ]
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.ctrForm,
                                  "prop-name": "clientNrStrMnt"
                                }
                              })
                            ],
                            1
                          )
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mx-1" }, [
                          _c(
                            "div",
                            { staticClass: "col-12 col-sm-2" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Bloc")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "input-group mb-0" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.ctrForm.clientBlocMnt,
                                      expression: "ctrForm.clientBlocMnt"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "clientBlocMnt",
                                    type: "text",
                                    name: "clientBlocMnt"
                                  },
                                  domProps: {
                                    value: _vm.ctrForm.clientBlocMnt
                                  },
                                  on: {
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.ctrForm,
                                          "clientBlocMnt",
                                          $event.target.value
                                        )
                                      },
                                      function($event) {
                                        _vm.ctrForm.clientBlocMnt = _vm.ctrForm.clientBlocMnt.toUpperCase()
                                      }
                                    ]
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.ctrForm,
                                  "prop-name": "clientBlocMnt"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-12 col-sm-2" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Scara")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "input-group mb-0" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.ctrForm.clientScaraMnt,
                                      expression: "ctrForm.clientScaraMnt"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "clientScaraMnt",
                                    type: "text",
                                    name: "clientScaraMnt"
                                  },
                                  domProps: {
                                    value: _vm.ctrForm.clientScaraMnt
                                  },
                                  on: {
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.ctrForm,
                                          "clientScaraMnt",
                                          $event.target.value
                                        )
                                      },
                                      function($event) {
                                        _vm.ctrForm.clientScaraMnt = _vm.ctrForm.clientScaraMnt.toUpperCase()
                                      }
                                    ]
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.ctrForm,
                                  "prop-name": "clientScaraMnt"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-12 col-sm-2" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Etaj")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "input-group mb-0" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.ctrForm.clientEtajMnt,
                                      expression: "ctrForm.clientEtajMnt"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "clientEtajMnt",
                                    type: "text",
                                    name: "clientEtajMnt"
                                  },
                                  domProps: {
                                    value: _vm.ctrForm.clientEtajMnt
                                  },
                                  on: {
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.ctrForm,
                                          "clientEtajMnt",
                                          $event.target.value
                                        )
                                      },
                                      function($event) {
                                        _vm.ctrForm.clientEtajMnt = _vm.ctrForm.clientEtajMnt.toUpperCase()
                                      }
                                    ]
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.ctrForm,
                                  "prop-name": "clientEtajMnt"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-12 col-sm-2" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Apartament")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "input-group mb-0" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.ctrForm.clientApMnt,
                                      expression: "ctrForm.clientApMnt"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "clientApMnt",
                                    type: "text",
                                    name: "clientApMnt"
                                  },
                                  domProps: { value: _vm.ctrForm.clientApMnt },
                                  on: {
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.ctrForm,
                                          "clientApMnt",
                                          $event.target.value
                                        )
                                      },
                                      function($event) {
                                        _vm.ctrForm.clientApMnt = _vm.ctrForm.clientApMnt.toUpperCase()
                                      }
                                    ]
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.ctrForm,
                                  "prop-name": "clientApMnt"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-12 col-sm-2" },
                            [
                              _c("label", { attrs: { for: "" } }, [
                                _vm._v("Interfon")
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "input-group mb-0" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.ctrForm.clientIntfMnt,
                                      expression: "ctrForm.clientIntfMnt"
                                    }
                                  ],
                                  staticClass: "form-control",
                                  attrs: {
                                    id: "clientIntfMnt",
                                    type: "text",
                                    name: "clientIntfMnt"
                                  },
                                  domProps: {
                                    value: _vm.ctrForm.clientIntfMnt
                                  },
                                  on: {
                                    input: [
                                      function($event) {
                                        if ($event.target.composing) {
                                          return
                                        }
                                        _vm.$set(
                                          _vm.ctrForm,
                                          "clientIntfMnt",
                                          $event.target.value
                                        )
                                      },
                                      function($event) {
                                        _vm.ctrForm.clientIntfMnt = _vm.ctrForm.clientIntfMnt.toUpperCase()
                                      }
                                    ]
                                  }
                                })
                              ]),
                              _vm._v(" "),
                              _c("show-error", {
                                attrs: {
                                  "form-name": _vm.ctrForm,
                                  "prop-name": "clientIntfMnt"
                                }
                              })
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "m-3" }, [
                    _vm._m(3),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-danger waves-effect waves-light pull-right m-r-10",
                        attrs: { type: "button" },
                        on: { click: _vm.cancel }
                      },
                      [_vm._v(_vm._s(_vm.trans("general.cancel")))]
                    )
                  ])
                ]
              )
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showAddCtr == false
      ? _c("section", { staticClass: "content m-3" }, [
          _vm.showListPanel
            ? _c("div", { staticClass: "card" }, [
                _vm._m(4),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "table-responsive" },
                  [
                    _c("div", { staticClass: "card-body p-0" }, [
                      _c(
                        "table",
                        { staticClass: "table table-striped projects" },
                        [
                          _vm._m(5),
                          _vm._v(" "),
                          _c(
                            "tbody",
                            _vm._l(_vm.contracte.data, function(contract) {
                              return _c("tr", [
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(contract.nrCtr)
                                  }
                                }),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    _vm._s(_vm._f("moment")(contract.dataCtr))
                                  )
                                ]),
                                _vm._v(" "),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(contract.clientName)
                                  }
                                }),
                                _vm._v(" "),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(contract.tipProdus)
                                  }
                                }),
                                _vm._v(" "),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(contract.ValEuro)
                                  }
                                }),
                                _vm._v(" "),
                                _c("td", {
                                  domProps: {
                                    textContent: _vm._s(contract.ValRON)
                                  }
                                }),
                                _vm._v(" "),
                                _vm._m(6, true),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "project-actions text-right" },
                                  [
                                    _c("div", { staticClass: "btn-group" }, [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-success dropdown-toggle btn-sm",
                                          attrs: {
                                            type: "button",
                                            "data-toggle": "dropdown"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "sr-only" },
                                            [_vm._v("Arata")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "dropdown-menu",
                                              attrs: { role: "menu" }
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "dropdown-item",
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.showDocContract(
                                                        contract
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Contract")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "a",
                                                {
                                                  staticClass: "dropdown-item",
                                                  on: {
                                                    click: function($event) {
                                                      $event.preventDefault()
                                                      return _vm.showDocProforma(
                                                        contract
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Proforma")]
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass: "btn btn-success btn-sm",
                                          attrs: { type: "button" }
                                        },
                                        [_vm._v("Print")]
                                      )
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-info btn-sm",
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.editContract(contract)
                                          }
                                        }
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-trash"
                                        }),
                                        _vm._v(" Edit\n                  ")
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        directives: [
                                          {
                                            name: "confirm",
                                            rawName: "v-confirm",
                                            value: {
                                              ok: _vm.confirmDelete(contract)
                                            },
                                            expression:
                                              "{ok: confirmDelete(contract)}"
                                          }
                                        ],
                                        key: contract.id,
                                        staticClass: "btn btn-danger btn-sm"
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-trash"
                                        }),
                                        _vm._v(" Sterge\n                  ")
                                      ]
                                    )
                                  ]
                                )
                              ])
                            }),
                            0
                          )
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    _c("pagination-record", {
                      attrs: {
                        "page-length": _vm.filterContractForm.page_length,
                        records: _vm.contracte
                      },
                      on: {
                        "update:pageLength": function($event) {
                          return _vm.$set(
                            _vm.filterContractForm,
                            "page_length",
                            $event
                          )
                        },
                        "update:page-length": function($event) {
                          return _vm.$set(
                            _vm.filterContractForm,
                            "page_length",
                            $event
                          )
                        },
                        updateRecords: _vm.getContracts
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("Contract nou")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row mx-1" }, [
      _c("div", { staticClass: "col-12 col-sm-4 m-3" }, [
        _c("h4", [_vm._v("Date contract")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row m-3" }, [
      _c("div", { staticClass: "col-12 col-sm-4" }, [
        _c("h4", [_vm._v("Adresa montaj")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "btn btn-info waves-effect waves-light pull-right",
        attrs: { type: "submit" }
      },
      [_c("span", [_vm._v("Salvare")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-header" }, [
      _c("h3", { staticClass: "card-title" }, [_vm._v("Lista contracte")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticStyle: { width: "1%" } }, [_vm._v("Nr. ctr")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Data")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Client")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Tip contract")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "7%" } }, [_vm._v("Val. Euro")]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "7%" } }, [_vm._v("Val. RON")]),
        _vm._v(" "),
        _c("th", { staticClass: "text-center", staticStyle: { width: "8%" } }, [
          _vm._v("Status")
        ]),
        _vm._v(" "),
        _c("th", { staticStyle: { width: "20%" } })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "text-right" }, [
      _c("span", { staticClass: "badge badge-success" }, [_vm._v("test")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }