var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { attrs: { id: "sidebarnav" } },
    [
      _c(
        "li",
        [
          _c("router-link", { attrs: { to: "/home", exact: "" } }, [
            _c("i", { staticClass: "fas fa-home fa-fw" }),
            _vm._v(" "),
            _c("span", { staticClass: "hide-menu" }, [
              _vm._v(_vm._s(_vm.trans("general.home")))
            ])
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.$route.meta.menu == "configuration"
        ? [
            _c(
              "li",
              [
                _c(
                  "router-link",
                  { attrs: { to: "/configuration/basic", exact: "" } },
                  [
                    _c("i", { staticClass: "fas fa-cog fa-fw" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "hide-menu" }, [
                      _vm._v(
                        _vm._s(_vm.trans("configuration.basic_configuration"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  { attrs: { to: "/configuration/logo", exact: "" } },
                  [
                    _c("i", { staticClass: "fas fa-image fa-fw" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "hide-menu" }, [
                      _vm._v(_vm._s(_vm.trans("general.logo")))
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  { attrs: { to: "/configuration/system", exact: "" } },
                  [
                    _c("i", { staticClass: "fas fa-cogs fa-fw" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "hide-menu" }, [
                      _vm._v(
                        _vm._s(_vm.trans("configuration.system_configuration"))
                      )
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  { attrs: { to: "/configuration/mail", exact: "" } },
                  [
                    _c("i", { staticClass: "fas fa-envelope fa-fw" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "hide-menu" }, [
                      _vm._v(_vm._s(_vm.trans("mail.mail_configuration")))
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.getConfig("multilingual")
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/configuration/locale", exact: "" } },
                      [
                        _c("i", { staticClass: "fas fa-globe fa-fw" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "hide-menu" }, [
                          _vm._v(_vm._s(_vm.trans("locale.locale")))
                        ])
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  { attrs: { to: "/configuration/role", exact: "" } },
                  [
                    _c("i", { staticClass: "fas fa-users fa-fw" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "hide-menu" }, [
                      _vm._v(_vm._s(_vm.trans("role.role")))
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  { attrs: { to: "/configuration/permission", exact: "" } },
                  [
                    _c("i", { staticClass: "fas fa-key fa-fw" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "hide-menu" }, [
                      _vm._v(_vm._s(_vm.trans("permission.permission")))
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  { attrs: { to: "/configuration/menu", exact: "" } },
                  [
                    _c("i", { staticClass: "fas fa-list fa-fw" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "hide-menu" }, [
                      _vm._v(_vm._s(_vm.trans("general.menu")))
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  { attrs: { to: "/configuration/authentication", exact: "" } },
                  [
                    _c("i", { staticClass: "fas fa-sign-in-alt fa-fw" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "hide-menu" }, [
                      _vm._v(_vm._s(_vm.trans("auth.authentication")))
                    ])
                  ]
                )
              ],
              1
            ),
            _vm._v(" "),
            _vm.getConfig("ip_filter")
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/configuration/ip-filter", exact: "" } },
                      [
                        _c("i", { staticClass: "fas fa-ellipsis-v fa-fw" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "hide-menu" }, [
                          _vm._v(_vm._s(_vm.trans("ip_filter.ip_filter")))
                        ])
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "li",
              [
                _c(
                  "router-link",
                  { attrs: { to: "/configuration/scheduled-job", exact: "" } },
                  [
                    _c("i", { staticClass: "fas fa-clock fa-fw" }),
                    _vm._v(" "),
                    _c("span", { staticClass: "hide-menu" }, [
                      _vm._v(_vm._s(_vm.trans("general.scheduled_job")))
                    ])
                  ]
                )
              ],
              1
            )
          ]
        : [
            _vm.hasPermission("contracte") && _vm.getConfig("show_todo_menu")
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/contracte", exact: "" } },
                      [
                        _c("i", { staticClass: "fas fa-file fa-fw" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "hide-menu" }, [
                          _vm._v(_vm._s(_vm.trans("contracte.contracte")))
                        ])
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermission("calculator") &&
            _vm.getConfig("show_calculator_menu")
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/calculator", exact: "" } },
                      [
                        _c("i", { staticClass: "fas fa-calculator fa-fw" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "hide-menu" }, [
                          _vm._v(_vm._s(_vm.trans("calculator.calculator")))
                        ])
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermission("list-user") && _vm.getConfig("show_user_menu")
              ? _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: "/user", exact: "" } }, [
                      _c("i", { staticClass: "fas fa-users fa-fw" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "hide-menu" }, [
                        _vm._v(_vm._s(_vm.trans("user.user")))
                      ])
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermission("access-todo") && _vm.getConfig("show_todo_menu")
              ? _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: "/todo", exact: "" } }, [
                      _c("i", { staticClass: "far fa-check-circle fa-fw" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "hide-menu" }, [
                        _vm._v(_vm._s(_vm.trans("todo.todo")))
                      ])
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermission("access-message") &&
            _vm.getConfig("show_message_menu")
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/message", exact: "" } },
                      [
                        _c("i", { staticClass: "far fa-envelope-open fa-fw" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "hide-menu" }, [
                          _vm._v(_vm._s(_vm.trans("message.message")))
                        ])
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermission("access-configuration") &&
            _vm.getConfig("show_configuration_menu")
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/configuration", exact: "" } },
                      [
                        _c("i", { staticClass: "fas fa-cogs fa-fw" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "hide-menu" }, [
                          _vm._v(
                            _vm._s(_vm.trans("configuration.configuration"))
                          )
                        ])
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermission("access-configuration") &&
            _vm.getConfig("show_backup_menu")
              ? _c(
                  "li",
                  [
                    _c("router-link", { attrs: { to: "/backup", exact: "" } }, [
                      _c("i", { staticClass: "fas fa-database fa-fw" }),
                      _vm._v(" "),
                      _c("span", { staticClass: "hide-menu" }, [
                        _vm._v(_vm._s(_vm.trans("backup.backup")))
                      ])
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermission("access-configuration") &&
            _vm.getConfig("show_email_template_menu")
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/email-template", exact: "" } },
                      [
                        _c("i", { staticClass: "far fa-envelope fa-fw" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "hide-menu" }, [
                          _vm._v(_vm._s(_vm.trans("template.email_template")))
                        ])
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermission("access-configuration") &&
            _vm.getConfig("show_email_log_menu")
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/email-log", exact: "" } },
                      [
                        _c("i", { staticClass: "fas fa-envelope fa-fw" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "hide-menu" }, [
                          _vm._v(_vm._s(_vm.trans("mail.email_log")))
                        ])
                      ]
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.hasPermission("access-configuration") &&
            _vm.getConfig("show_activity_log_menu")
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/activity-log", exact: "" } },
                      [
                        _c("i", { staticClass: "fas fa-bars fa-fw" }),
                        _vm._v(" "),
                        _c("span", { staticClass: "hide-menu" }, [
                          _vm._v(_vm._s(_vm.trans("activity.activity_log")))
                        ])
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }