var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-titles p-3 border-bottom" }, [
      _c("h3", { staticClass: "text-themecolor" }, [
        _vm._v(_vm._s(_vm.trans("sms.sms")) + "\n            "),
        _c(
          "button",
          {
            staticClass: "btn btn-info btn-sm pull-right",
            on: {
              click: function($event) {
                return _vm.$router.push("/home")
              }
            }
          },
          [
            _c("i", { staticClass: "fas fa-home" }),
            _vm._v(" "),
            _c("span", { staticClass: "d-none d-sm-inline" }, [
              _vm._v(_vm._s(_vm.trans("general.home")))
            ])
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container-fluid p-0" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card" }, [
            _c(
              "div",
              { staticClass: "card-body p-4" },
              [
                _c("show-tip", { attrs: { module: "sms", tip: "tip_sms" } }),
                _vm._v(" "),
                _c("h4", { staticClass: "card-title" }, [
                  _vm._v(_vm._s(_vm.trans("sms.sms")))
                ]),
                _vm._v(" "),
                _c(
                  "form",
                  {
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return _vm.submit($event)
                      },
                      keydown: function($event) {
                        return _vm.configForm.errors.clear($event.target.name)
                      }
                    }
                  },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c("div", { staticClass: "col-12 col-sm-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("sms.api_key")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configForm.nexmo_api_key,
                                  expression: "configForm.nexmo_api_key"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                value: "",
                                name: "nexmo_api_key",
                                placeholder: _vm.trans("sms.api_key")
                              },
                              domProps: { value: _vm.configForm.nexmo_api_key },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.configForm,
                                    "nexmo_api_key",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "nexmo_api_key"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("sms.api_secret")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configForm.nexmo_api_secret,
                                  expression: "configForm.nexmo_api_secret"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                value: "",
                                name: "nexmo_api_secret",
                                placeholder: _vm.trans("sms.api_secret")
                              },
                              domProps: {
                                value: _vm.configForm.nexmo_api_secret
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.configForm,
                                    "nexmo_api_secret",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "nexmo_api_secret"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c("label", { attrs: { for: "" } }, [
                              _vm._v(_vm._s(_vm.trans("sms.sender_mobile")))
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configForm.nexmo_sender_mobile,
                                  expression: "configForm.nexmo_sender_mobile"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                value: "",
                                name: "nexmo_sender_mobile",
                                placeholder: _vm.trans("sms.sender_mobile")
                              },
                              domProps: {
                                value: _vm.configForm.nexmo_sender_mobile
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.configForm,
                                    "nexmo_sender_mobile",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "nexmo_sender_mobile"
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-12 col-sm-3" }, [
                        _c(
                          "div",
                          { staticClass: "form-group" },
                          [
                            _c(
                              "label",
                              { attrs: { for: "" } },
                              [
                                _vm._v(
                                  _vm._s(_vm.trans("sms.receiver_mobile")) + " "
                                ),
                                _c("show-tip", {
                                  attrs: {
                                    module: "sms",
                                    tip: "tip_receiver_mobile",
                                    type: "field"
                                  }
                                })
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.configForm.nexmo_receiver_mobile,
                                  expression: "configForm.nexmo_receiver_mobile"
                                }
                              ],
                              staticClass: "form-control",
                              attrs: {
                                type: "text",
                                value: "",
                                name: "nexmo_receiver_mobile",
                                placeholder: _vm.trans("sms.receiver_mobile")
                              },
                              domProps: {
                                value: _vm.configForm.nexmo_receiver_mobile
                              },
                              on: {
                                input: function($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.$set(
                                    _vm.configForm,
                                    "nexmo_receiver_mobile",
                                    $event.target.value
                                  )
                                }
                              }
                            }),
                            _vm._v(" "),
                            _c("show-error", {
                              attrs: {
                                "form-name": _vm.configForm,
                                "prop-name": "nexmo_receiver_mobile"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-info waves-effect waves-light pull-right m-t-10",
                        attrs: { type: "submit" }
                      },
                      [_vm._v(_vm._s(_vm.trans("general.save")))]
                    )
                  ]
                )
              ],
              1
            )
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }