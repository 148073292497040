<template>

<div id="invoice">
    <div class="toolbar hidden-print">
        <div class="text-right">
            <button id="printInvoice" class="btn btn-info" @click.prevent="print('invoice')"><i class="fa fa-print"></i> Print</button>
            <button class="btn btn-info"><i class="fa fa-file-pdf-o"></i> Export as PDF</button>
        </div>
        <hr>
    </div>

    <div class="invoice overflow-auto">
        <div style="min-width: 600px">
            <header>
                <div class="row">
                    <div class="col">
                        <a target="_blank" href="https://jaluzele-bucuresti.ro/">
                            <img src="https://jaluzele-bucuresti.ro/wp-content/uploads/2020/01/logo-jaluzele-bucuresti.png" data-holder-rendered="true" />
                            </a>
                    </div>
                    <div class="col company-details">
                        <h2 class="name">
                            <a target="_blank" href="https://jaluzele-bucuresti.ro/">
                            Jaluzele Bucuresti
                            </a>
                        </h2>
                        <div>Bucuresti</div>
                        <div>(123) 456-789</div>
                        <div>comenzi@jaluzele-bucuresti.ro</div>
                    </div>
                </div>
            </header>
            <main>
                <div class="row contacts">
                    <div class="col invoice-to">
                        <div class="text-gray-light">Beneficiar:</div>
                        <h2 class="to">{{printStore.clientName}}</h2>
                        <div class="address">{{printStore.judDom}}, Strada: {{printStore.clientStrDom}}, Numar: {{printStore.clientNrStrDom}}, {{printStore.clientSectLocDom}}</div>
                        <div class="email">{{printStore.clientMail}}</div>
                    </div>
                    <div class="col invoice-details">
                        <h1 class="invoice-id">Proforma nr. {{printStore.nrCtr}}</h1>
                        <div class="date">Data: {{printStore.dataCtr}}</div>
                    </div>
                </div>
                <table border="0" cellspacing="0" cellpadding="0">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th class="text-left">Denumire</th>
                            <th class="text-right">U.M.</th>
                            <th class="text-right">Cantitate</th>
                            <th class="text-right">Pret (lei)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="no">01</td>
                            <td class="text-left"><h3>
                                {{printStore.tipProdus}}
                               </h3>
                            </td>
                            <td class="unit">Buc.</td>
                            <td class="qty">1</td>
                            <td class="total">{{printStore.ValRON}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                       
                        <tr>
                            <td colspan="2"></td>
                            <td colspan="2">TOTAL</td>
                            <td>{{printStore.ValRON}} lei</td>
                        </tr>
                    </tfoot>
                </table>
                <div class="thanks"></div>
                <div class="thanks">Multumim pentru incredere!</div>
                <div class="notices">
                    <div>Cont:</div>
                    <div class="notice">Factura se poate achita in contul</div>
                    <div class="notice">IBAN: 1212 1122 11111 11111 1111 </div>
                    <div class="notice">Banca: Nume Banca </div>
                </div>
            </main>
            <footer>
                Documentul a fost generat cu mijloace informatice. Circula fara semnatura si stampila.
            </footer>
        </div>
        <!--DO NOT DELETE THIS div. IT is responsible for showing footer always at the bottom-->
        <div></div>
    </div>
</div>
</template>
<script>
    import switches from "vue-switches";
    import datepicker from "vuejs-datepicker";
    import dateRangePicker from "../../components/date-range-picker";
    import sortBy from "../../components/sort-by";


    export default {
       // components : { printData },
        data() {
            return {
                printStore:{       
                    id: null,
                    nrCtr: "",
        dataCtr: "",
        tipProdus: "",
        ValEuro: "",
        ValRON: "",
        cursBNR: "",
        clientName: "",
        clientPhone: "",
        clientPhone2: "",
        clientMail: "",
        tipClient: "pf",
        codUnicPJ: "",
        regCom: "",
        reprezentant: "",
        judDom: "",
        clientSectLocDom: "",
        clientStrDom: "",
        clientNrStrDom: "",
        clientBlocDom: "",
        clientScaraDom: "",
        clientEtajDom: "",
        clientApDom: "",
        clientIntfDom: "",
        judMnt: "",
        clientSectLocMnt: "",
        clientStrMnt: "",
        clientNrStrMnt: "",
        clientBlocMnt: "",
        clientScaraMnt: "",
        clientEtajMnt: "",
        clientApMnt: "",
        clientIntfMnt: "",
        clientAdresaMCheck: 0
}
            }
            

        },
        mounted(){
            if(!helper.hasPermission('proformacontract')){
                helper.notAccessibleMsg();
                this.$router.push('/contracte');
            }
        //    if(this.$store.getters.getPrintData.id == null){
        //         helper.notAccessibleMsg();
        //         this.$router.push('/contracte');
        //     }
        console.log(this.$store.getters.getPrintData);
        this.printStore = this.$store.getters.getPrintData;
        },
          methods: {
              print(el){
                  
var restorepage = $('body').html();
var printcontent = $('#' + el).clone();
var enteredtext = $('#text').val();
$('body').empty().html(printcontent);
window.print();
$('body').html(restorepage);
$('#text').html(enteredtext);

            // var headstr = "<html><head><title></title></head><body>";
            // var footstr = "</body>";
            // var newstr = document.all.item(el).innerHTML;
            // var oldstr = document.body.innerHTML;
            // document.body.innerHTML = headstr + newstr + footstr;
            // window.print();
            // document.body.innerHTML = oldstr;
            // return false;

    // var mywindow = window.open('', 'PRINT', 'height=400,width=600');

    // mywindow.document.write('<html><head><title>' + document.title  + '</title>');
    // mywindow.document.write('</head><body >');
    // mywindow.document.write('<h1>' + document.title  + '</h1>');
    // mywindow.document.write(document.getElementById(el).innerHTML);
    // mywindow.document.write('</body></html>');

    // mywindow.document.close(); // necessary for IE >= 10
    // mywindow.focus(); // necessary for IE >= 10*/

    // mywindow.print();
    // mywindow.close();

    // return true;



        },


              }
          
    }
</script>
