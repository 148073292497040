var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12 col-sm-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", { attrs: { for: "" } }, [
              _vm._v(_vm._s(_vm.trans("calculator.nume")))
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.calcForm.nume,
                  expression: "calcForm.nume"
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                value: "",
                name: "nume",
                placeholder: _vm.trans("calculator.nume")
              },
              domProps: { value: _vm.calcForm.nume },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.calcForm, "nume", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("show-error", {
              attrs: { "form-name": _vm.calcForm, "prop-name": "title" }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-sm-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", { attrs: { for: "" } }, [
              _vm._v(_vm._s(_vm.trans("calculator.tel")))
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.calcForm.tel,
                  expression: "calcForm.tel"
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                value: "",
                name: "tel",
                placeholder: _vm.trans("calculator.tel")
              },
              domProps: { value: _vm.calcForm.tel },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.calcForm, "tel", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("show-error", {
              attrs: { "form-name": _vm.calcForm, "prop-name": "tel" }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-12 col-sm-4" }, [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", { attrs: { for: "" } }, [
              _vm._v(_vm._s(_vm.trans("calculator.mail")))
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.calcForm.mail,
                  expression: "calcForm.mail"
                }
              ],
              staticClass: "form-control",
              attrs: {
                type: "email",
                value: "",
                name: "mail",
                placeholder: _vm.trans("calculator.mail")
              },
              domProps: { value: _vm.calcForm.mail },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.calcForm, "mail", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c("show-error", {
              attrs: { "form-name": _vm.calcForm, "prop-name": "mail" }
            })
          ],
          1
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-info waves-effect waves-light pull-right",
        attrs: { type: "button" },
        on: {
          click: function($event) {
            return _vm.$emit("ok")
          }
        }
      },
      [
        _vm.id
          ? _c("span", [_vm._v(_vm._s(_vm.trans("calculator.update")))])
          : _c("span", [_vm._v(_vm._s(_vm.trans("calculator.save")))])
      ]
    ),
    _vm._v(" "),
    !_vm.id
      ? _c(
          "button",
          {
            staticClass:
              "btn btn-danger waves-effect waves-light pull-right m-r-10",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                return _vm.$emit("cancel")
              }
            }
          },
          [_vm._v(_vm._s(_vm.trans("general.cancel")))]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }